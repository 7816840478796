<template>
  <div>
    <section>
      <nav class="navbar navbar-expand-md fixed-top">
        <div class="container-fluid">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fa fa-bars"></i>
          </button>
          <div>
            <LocaleSwitcher />
          </div>
          <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div>
              <router-link to="/"><span data-bs-toggle="collapse"  data-bs-target=".navbar-collapse" ><img class="nav-logo" src="../assets/logo-white.png" alt=""></span></router-link>   
            </div>
            <ul class="navbar-nav mx-auto">
              <li class="nav-item">
                <router-link to="/menu" class="nav-link"><span data-bs-toggle="collapse"  data-bs-target=".navbar-collapse" >{{ $t('nav.menu') }}</span></router-link> 
              </li>
              <li class="nav-item">
                <router-link to="/about" class="nav-link"><span data-bs-toggle="collapse"  data-bs-target=".navbar-collapse" >{{ $t('nav.about') }}</span></router-link> 
              </li>
              <li class="nav-item">
                <router-link to="/contact" class="nav-link"><span data-bs-toggle="collapse"  data-bs-target=".navbar-collapse" >{{ $t('nav.contact') }}</span></router-link>
              </li>
              <li class="nav-item">
                <a href="https://www.dineout.is/sjavarsetrid?isolation=true" target="_blank" class="nav-link"><span data-bs-toggle="collapse"  data-bs-target=".navbar-collapse" >{{ $t('nav.book') }}</span></a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </section>
    
  </div>
</template>

<script>
import LocaleSwitcher from "./LocaleSwitcher.vue";

export default {
  name: 'Navbar',
 components: { LocaleSwitcher }
}
</script>

<style scoped> 
.navbar {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100%;
}
.nav-logo {
  max-height: 30px;
  margin-left: 20px;
  height: 86px;
  margin-top: 30px;
}
.nav-link {
  font-size: 19px;
  color: #ffffff !important;
  margin-bottom: 10px;
  margin: 0px 20px 17px;
  margin-top: 40px;
  float: left;
}
.navbar-toggler {
  color: #ffffff !important;
}
.navbar-toggler .fa {
  color: #ffffff;
  font-size: 30px;
}
.btn {
  border: 2px solid #ffffff;
  margin-top: 20px;
  padding: 6px 26px;
  font-size: 16px;
  margin-bottom: 110px;
}
.bord {
  padding-bottom: 60px;
}
.intro {
  padding-top: 100px;
}
</style>


