<template >
  <div class="locale-switcher">
    <label for="option1">
        <input type="radio" class="btn-check" name="options" id="option1" value="is" v-model="$i18n.locale" autocomplete="off">
        <img src="../assets/flag-iceland.png" class="btn btn-outline-dark" alt="">
    </label>

    <label for="option2">
        <input type="radio" class="btn-check" name="options" id="option2" value="en" v-model="$i18n.locale" autocomplete="off">
        <img src="../assets/flag-uk.png" class="btn btn-outline-dark" alt="">
    </label>
  </div> 
</template>

<script>
export default {
  name: "LocaleSwitcher"
}
</script>

<style scoped>
.btn {
  margin: 0;
  padding: 0;
  margin-right: 20px;
}
</style>