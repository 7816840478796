export default {
  "nav": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matseðill"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um okkur"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hafa samband"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bóka borð"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velkomin á veitingarstaðinn okkar"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meira"])}
  },
  "about": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sagan okkar"])},
    "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Við erum tvenn hjón úr ólíkum áttum sem að eigum það sameiginlegt að vera aðflutt í bæjarfélagið. Eftir stutta vináttu og sameinaðar fjölskyldur varð fljótt ljóst að stór draumur var enn eitt sem að við áttum sameiginlegt. Ást á matargerð, gestrisni og ákveðin hugsjón gerði því stóra drauminn okkar allra að veruleika og kynnum við með stolti Sjávarsetrið. "])},
    "long1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Við erum tvenn hjón Begga og Elfar, og Arna og Símon. Í sitthvoru lagi höfðum við lengi látið okkur dreyma um að stofna eigin fjölskyldu rekstur. Eftir að við, Begga og Arna urðum nánar vinkonur héldum við ótal matarboð. Skiptumst á að elda góðan mat og taka á móti fjölskyldum hvers annars og gæða okkur á góðum mat og njóta saman. Við vorum eftir stutta vináttu orðnar það nánar að þegar vágesturinn covid tróð sér inn á fjölskyldur okkar, ákváðum við að taka það út saman. Eitt skrautlegt covid- og vínglasa kvöld kom í ljós að við áttum sameiginlegan draum. Sá draumur var að kaupa Vitann sem var veitingastaður rekinn í Sandgerðisbæ í tæp 40 ár."])},
    "long2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekki löngu eftir þetta tiltekna spjall eyddum við annari kvöldstund saman, þar sem að við formlega ræddum möguleika okkar á að gera drauminn að veruleika. Degi síðar var farið að skoða staðinn og örfáum dögum seinna gerðum við kauptilboð. Áður en við vissum af eða innan mánaðar var kaupsamningur undirritaður og við kominn með lykla í hendurnar. Óteljandi tímar fóru í endurbætur, málningavinnu, þrif og heildar upplyftingu á staðnum. Allar hendur voru úti og fengum við gott fólk í kringum okkur til þess að hanna logo, heimasíðu og ýmislegt annað og var nafnið Sjávarsetrið valið. Nýju upphafi fylgdi nýtt nafn."])},
    "long3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjávarsetrið í heild hefur því gríðarlega persónulega og mikilvæga merkingu fyrir okkur."])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staðsetning"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sýna kort"])},
    "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjávarsetrið er nýr veitingarstaður staðsettur við höfnina í Sandgerði. Hlýleg og notaleg innviði hússins grípa mann um leið og gengið er inn svo hér ætti öllum að líða vel."])},
    "long4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjávarsetrið er nýr veitingarstaður staðsettur við höfnina í Sandgerði. Hlýleg og notaleg innviði hússins grípa mann um leið og gengið er inn svo hér ætti öllum að líða vel. Sjávarsetrið er hugsjón tveggja ungra hjóna sem koma úr ólíkum áttum og hafa ólíkan matarsmekk."])},
    "long5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concept staðarins skapaðist úr þessu kombói. Á meðan sumir í hópnum vildu sjávarréttastað vildu aðrir notalegan stað með örlítið fjölbreyttara úrvali. Úr varð týpískur veitingastaður með sjávarréttaívafi."])}
  },
  "contact": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okkur þætti vænt um að heyra frá þér"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endilega hafðu samband ef þú vilt bóka borð fyrir hópa eða hefur einhverjar spurningar"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Þú getur sent okkur póst á "])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" eða haft samband á opnunartíma í síma 556-4400"])}
  },
  "open": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnunartími"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Þri - Fim"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mánudagur"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Föstudagur"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laugardagur"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunnudagur"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokað"])}
  },
  "verslo": {
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokað helgina 8. 9. og 10. sept"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setursfjölskyldan lætur því miður ekki 5 daga sumarfrí nægja og því er lokað um helgina 8. 9. Og 10. September. Við biðjumst velvirðingar á þeim óþægindum sem það kann að valda"])}
  }
}