export default {
  "nav": {
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a table"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to our restaurant"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])}
  },
  "about": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Story"])},
    "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are two couples from different backgrounds who moved to Sandgerði a few years ago. After a short friendship and united families, it quickly became clear that a big dream was one more thing we had in common. Love of cooking, hospitality and a certain ideal made our big dream come true and we proudly present Sjávarsetrið. "])},
    "long1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are two couples, Begga and Elfar, and Arna and Símon. Separately, we had long dreamed of starting our own family business. After we, Begga and Arna became closer friends, we had countless dinner parties. We took turns cooking good food, hosting each other's families, enjoying good food and good company. After a short friendship, we became so close that when the stranger covid invaded our lives, we decided to stick it out together. One fancy night over glasses of wine we realised that we had a common dream. That dream was to buy Vitinn, which was a restaurant that had operated in Sandgerði for almost 40 years."])},
    "long2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not long after this particular chat, we spent another evening together, where we formally discussed how to make this dream of ours come true. A day later we inspected the restaurant and a few days later we made an offer. Before we knew or within a month, the purchase agreement was signed and the keys were in our hands. Countless hours were spent on renovations, paint jobs, cleaning and overall uplift of the place. All hands were out and we got good people around us to design a logo, website and various other things and the name Sjávarsetrið was chosen. A new beginning came with a new name."])},
    "long3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjávarsetrið therefore has an extremely personal and important meaning for us."])},
    "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get directions"])},
    "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjávarsetrið is a new restaurant located at the harbor in Sandgerði. Warm and welcoming interior should make everyone feel comfortable."])},
    "long4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sjávarsetrið is a new restaurant located at the harbor in Sandgerði. Warm and welcoming interior should make everyone feel comfortable. Sjávarsetrið is the vision of two young couples who come from different backgrounds and have different tastes in food."])},
    "long5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The concept of the place was created from this combo. While some in the group wanted a seafood place, others wanted a cozy place with a slightly more diverse selection. It turned out to be a typical restaurant with a seafood twist."])}
  },
  "contact": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We would love to hear from you"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact us if you would like to book a table for groups or if you have any questions"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All inquiries can be sent to "])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" or you can give us a call during opening hours at: +354 556-4400"])}
  },
  "open": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening Hours"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue - Thu"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])}
  },
  "verslo": {
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed for the weekend 8th 9th and 10th of September"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The “Seturs family” unfortunately will be taking a short “summer vacation” this weekend and we will be closed the 8th 9th and 10th of September. We apologize for the inconvenience this may cause."])}
  }
}