<template>
  <div>
    <section>
      <nav class="navbar navbar-expand-md">
        <div class="container-fluid">
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fa fa-bars"></i>
          </button>
          <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div>
              <router-link to="/"><img class="nav-logo" src="../assets/logo-white.png" alt=""></router-link>   
            </div>
            <ul class="navbar-nav mx-auto">
              <li class="nav-item">
                <router-link to="/menu" class="nav-link">{{ $t('nav.menu') }}</router-link> 
              </li>
              <li class="nav-item">
                <router-link to="/about" class="nav-link">{{ $t('nav.about') }}</router-link> 
              </li>
              <li class="nav-item">
                <router-link to="/contact" class="nav-link">{{ $t('nav.contact') }}</router-link>
              </li>
              <li class="nav-item">
                <a href="https://www.dineout.is/sjavarsetrid?isolation=true" target="_blank" class="nav-link">{{ $t('nav.book') }}</a>
              </li>
            </ul>
            <div>
              <LocaleSwitcher />
            </div>
          </div>
        </div>
      </nav>
    </section>
    
  </div>
</template>

<script>
import LocaleSwitcher from "./LocaleSwitcher.vue";

export default {
  name: 'Navbar',
 components: { LocaleSwitcher }
}
</script>

<style scoped>
.navbar {
  background-color: #262526;
  padding-left: 15px;
  padding-right: 15px;
}
.nav-logo {
  max-width: 150px;
}
.nav-link {
  font-size: 19px;
  color: #ffffff !important;
  margin: 0 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.nav-link:hover {
  border-bottom: 2px solid #ffffff;
  margin-bottom: -2px;
}
.btn {
  border: 2px solid #ffffff;
  margin-top: 20px;
  padding: 6px 26px;
  font-size: 16px;
  margin-bottom: 110px;
}
@media only screen and (max-width: 870px) {
  .nav-link {
    margin: 0 5px;
    font-size: 16px;
  }
}

</style>


