<template>
  <div id="app">
    <div class="d-md-none"><NavbarMobile/></div>
    <div class="d-none d-md-block"><Navbar/></div>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import NavbarMobile from './components/NavbarMobile.vue'
import Footer from './components/Footer.vue'


export default {
  name: 'app',
  components: {
    Navbar,
    NavbarMobile,
    Footer
  }
}

</script>

<style>
* {
  font-family: 'Noto Sans', sans-serif;
}
</style>
