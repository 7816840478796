<template>
  <div class="card text-center happy-hour">
    <div class="card-body">
      <h1>Happy Hour</h1>
      <h1>16:00 - 19:00</h1>
    </div>
  </div>
</template>

<script>
export default {
    name: 'HappyHour'
}
</script>

<style scoped>
.happy-hour {
  background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url("../assets/happyhour.jpg");
  background-size: cover;
  background-position: center;
  max-width: 500px;
  border: none;
  margin: 0 auto; 
  float: none;
  padding-top: 10px;
  padding-bottom: 10px;
}
.card-body {
  color: white;
  padding-top: 460px;
  padding-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .jolahladbord {
    padding-bottom: 30px;
  }
  .card-body {
    padding-top: 300px;
    padding-bottom: 10px;
}

}
</style>