<template>
  <div class="home">
    <div class="container-fluid">
      <div class="jumbotron text-center">
        <div class="intro">
          <img src="../assets/logo-white-text.png"><br>
       </div>
      </div>
      <div class="row">
        <div class="col" style="background-color: #262526">
          <h2 class="text-center pt-5" style="color: #ffffff">{{ $t('nav.welcome')}}</h2>
          <div><Message/></div>
          <div class="images">
            <div class="row">
              <div class="col-4">
                <img src="../assets/seating1.jpg" alt="">
              </div>
              <div class="col-4">
                <img src="../assets/oyster.jpg" alt="">
              </div>
              <div class="col-4">
                <img src="../assets/seating2.jpg" alt="">
              </div>
            </div>
          </div>        
        </div>      
      </div>
      <div class="row" style="background-color: #262526">
        <div class="col">
          <div class="home-text text-center">
            <!-- <img src="../assets/jolahladbord.jpg" alt="" class="jolahladbord"> -->
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 flexbox">
                <img src="../assets/sjavarsetrid.jpg" alt="" class="about-img">
                <p class="flex-item">{{ $t('about.restaurant')}}</p>
                <router-link to="/about" class="btn btn-secondary flex-item">{{ $t('nav.more')}}</router-link>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 flexbox">
                <img src="../assets/hopmynd.jpg" alt="" class="about-img">
                <p class="flex-item">{{ $t('about.short')}}</p>
                <router-link to="/about" class="btn btn-secondary flex-item">{{ $t('nav.more')}}</router-link>
              </div>
            </div>
          </div>        
        </div>      
      </div>
      <div class="row" style="background-color: #262526">
        <div class="col">
          <div>
            <HappyHour/>
          </div>
          <div class="hours">
            <OpeningHours/>
          </div>
        </div>      
      </div>
    </div>
  </div>
</template>

<script>
import OpeningHours from '../components/OpeningHours.vue'
import HappyHour from '../components/HappyHour.vue'
import Message from '../components/Message.vue'

export default {
  name: 'Home',
  components: {
    OpeningHours,
    HappyHour,
    Message
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.jolahladbord {
  max-width: 55%;
}
.btn-secondary {
  padding: 10px;
  font-weight: 500;
  margin-top: 20px !important;
}
.jumbotron {
  background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url("../assets/panel-wheel.jpg");
  background-size: cover;
  background-position: center;
}
.intro {
  padding-top: 150px;
  padding-bottom: 150px;
}
.intro img {
  max-width: 60%;
  max-height: 200px;
}
.home-img {
  max-width: 100%;
}
.about-img {
  max-width: 60%;
  margin: auto;
  padding-top: 20px;
}
.flexbox {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.flex-item {
  max-width: 70%;
  color: #ffffff;
  margin: auto;
  padding-top: 10px;
}
.home-text {
  margin-top: 60px;
  margin-bottom: 60px;
}
.home-text p {
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 20px !important;
}
.images {
  margin-top: 40px;
}
.images img {
  min-height: 100%;
  max-width: 100%;
}
.hours {
  padding-top: 30px;
  padding-bottom: 50px;
}
@media only screen and (max-width: 767px) {
.jolahladbord {
  max-width: 100%;
}
.flex-item {
  margin-top: 50px;
}
.about-img {
  margin-top: 20px;
}
.intro {
  padding-bottom: 100px;
}
.hours {
  padding-top: 40px;
}
}
</style>