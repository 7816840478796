<template>
  <div class="card">
    <div class="card-body">
        <h5 class="card-title">{{ $t('verslo.closed')}}</h5>
        <p class="card-text">{{ $t('verslo.message')}}</p>
    </div>
  </div>
</template>

<script>
export default {
    name: 'OpeningHours'
}
</script>

<style scoped>
.card {
  background-color: #FA5F98;
  max-width: 19rem;
  color: #ffffff;
  border: none;
  margin: 0 auto; 
  float: none;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
}
.card-title {
  margin-bottom: 30px;
}
.card-text {
  margin: 0;
  float: left;
}
.hours {
  text-align: end;
}
</style>