<template>
  <footer class="footer text-center">
    <div class="container p-4">
          <h5 class="text-uppercase">Sjávarsetrið</h5>
          <p>Vitatorg 7</p>
          <p>245 Suðurnesjabær</p>
          <p>+354 556 4400</p>
          <p>sjavarsetrid@sjavarsetrid.is</p>
          <button class="btn btn-dark"><a href="https://www.facebook.com/Sjavarsetrid" target="_blank"><i class="fa fa-facebook fa-2x"></i></a></button>
          <!-- <button class="btn btn-dark"><i class="fa fa-instagram fa-2x"></i></button> -->
    </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style scoped>
.footer {
    background-color: #262526 ;
    color: #ffffff
}
a {
    color: inherit;
}
.btn-dark {
  background-color: #262526 ;
  border: none;
  margin-right: 5px;
  margin-left: 5px;    
}
</style>